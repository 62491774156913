//https://www.gatsbyjs.com/docs/adding-search-with-algolia/
import algoliasearch from "algoliasearch/lite";
import { createRef, default as React, useState, useMemo } from "react";
import { InstantSearch, SearchBox } from "react-instantsearch-dom";
import useClickOutside from "./use-click-outside";
//import SearchBox from "./search-box";
import SearchResult from "./search-result";
import { SearchIcon, XIcon } from "@heroicons/react/solid";

export default function Search({ indices }) {
  const rootRef = createRef();
  const [query, setQuery] = useState();
  const [hasFocus, setFocus] = useState(false);

  //https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react/#mocking-a-search-request
  //const searchClient = useMemo(
  const algoliaClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  );

  const searchClient = {
    ...algoliaClient,
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        });
      }

      return algoliaClient.search(requests);
    },
  };

  useClickOutside(rootRef, () => setFocus(false));

  // https://www.algolia.com/doc/api-reference/widgets/react/
  return (
    <div
      ref={rootRef}
      className="relative container mx-auto max-w-5xl px-5 md:px-0"
    >
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <SearchBox
          className="font-sans"
          onFocus={() => setFocus(true)}
          hasFocus={hasFocus}
          searchAsYouType={false}
          submit={<SearchIcon className="ml-2 w-7 h-7 text-cyan-300" />}
          reset={<XIcon className="ml-2 w-7 h-7 text-cyan-300" />}
          translations={{
            submitTitle: "このキーワードで検索",
            resetTitle: "キーワードを消去",
            placeholder: "キーワードを入力してEnter",
          }}
        />
        <SearchResult
          className={`absolute top-14 left-1/2 -translate-x-1/2 container max-w-5xl rounded-md border border-gray-200 bg-white z-20 max-h-screen p-5 overflow-y-scroll scrolling-touch ${
            query && query.length > 0 && hasFocus ? "visible" : "invisible"
          }`}
          indices={indices}
        />
      </InstantSearch>
    </div>
  );
}
