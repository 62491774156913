//https://www.gatsbyjs.com/docs/adding-search-with-algolia/
import { Link } from "gatsby";
import { default as React } from "react";
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
  PoweredBy,
  Pagination,
} from "react-instantsearch-dom";

const HitCount = connectStateResults(({ searchResults, searching }) => {
  const hitCount = searchResults && searchResults.nbHits;

  if (searching) {
    return (
      <div className="text-center py-3 md:py-5">
        検索中です。しばらくお待ちください。
      </div>
    );
  } else {
    return hitCount > 0 ? (
      <div className="HitCount mb-5 text-right font-sans text-gray-600">
        {hitCount}件の結果
      </div>
    ) : (
      <div className="text-center py-3 md:py-5">
        検索にヒットしませんでした。キーワードを変えてもう一度お試しください。
      </div>
    );
  }
});

const PageHit = ({ hit }) => (
  <div className="mb-5">
    <Link to={hit.slug} className="font-sans underline">
      <h4>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </h4>
    </Link>
    <Snippet attribute="excerpt" hit={hit} tagName="mark" className="text-sm" />
  </div>
);

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits className="Hits" hitComponent={PageHit} />
  </Index>
);

const SearchResult = ({ indices, className }) => {
  return (
    <div className={className}>
      {indices.map((index) => (
        <HitsInIndex index={index} key={index.name} />
      ))}
      <Pagination className="flex justify-center" showLast />
      <PoweredBy className="flex items-center justify-end font-sans text-sm text-gray-400" />
    </div>
  );
};

export default SearchResult;
